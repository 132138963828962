
@import '../../css/root.css';

:root {
    --responder-background-1: #e6e5e5;
    --responder-grey-1: #d0d5dd;
    --popover-background: #ffcccc;
}

a {
    color: var(--blue-600);
}

a:hover {
    color: var(--blue-600);
}

.dev-message {
    display: flex;
    justify-content: center;
    border: solid red;
    border-width: 1px;
    border-radius: 3px;
    font-weight: 800;
    margin-top: 3px;
}

#dashboard-body-div {
    display: flex;
    flex-direction: column;
    align-items: normal;
    padding: 0 1% 0 0;
}

#oser-dash-main-title-fullscreen-btn-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
}

#oser-dash-main-title-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
}

#oser-dash-main-title-count-separator {
    display: flex;
}

#oser-dash-main-title {
    font-size: 22px;
    margin: 0;
}

.responder-type-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0 3px 0;
}

.responder-main-title-count {
    margin: 0 0 0 1%;
    padding: 1px 8px 1px 8px;
    font-size: 1.5em;
    border-radius: 3px;
    background-color: var(--responder-background-1);
    height: fit-content;
}

.responder-type-title-text {
    margin: 0;
    font-size: 20px;
    height: fit-content;
}

.responder-type-title-count {
    margin: 0 0 0 12px;
    padding: 1px 8px 1px 8px;
    font-size: 1.5em;
    border-radius: 3px;
    background-color: var(--responder-background-1);
    height: fit-content;
    align-items: baseline;
}

#responder-options-menu {
    display: flex;
    align-items: center;
}

#copy-url-btn-wrapper {
    display: flex;
    position: relative;
}

#copy-url-btn {
    display: inline-flex;
    padding: 2px 12px 2px 12px;
    margin: 0 6px 0 0;
    background-color: inherit;
    border: none;
    color: var(--blue-600);
    font-size: 14px;
    font-weight: 600;
}

#copy-url-btn:hover {
    color: var(--blue-700);
}

#export-btn-wrapper {
    display: flex;
    position: relative;
}

#export-btn {
    display: inline-flex;
    padding: 2px 12px 2px 12px;
    margin: 0 6px 0 0;
    background-color: inherit;
    border: none;
    color: var(--blue-600);
    font-size: 14px;
    font-weight: 600;
}

#export-btn:hover {
    color: var(--blue-700);
}

#export-option-csv-wrapper {
    position: absolute;
    top: 50px;
    right: 6%;
    margin-top: 4px;
    border-radius: 6px;
    box-shadow: 0 4px 14px gray;
    background-color: white;
    z-index: 100000;
}

.export-options-button-menu {
    padding: 4px 0;
    margin: 0;
}

.export-btn-csv {
    border: none;
    background-color: transparent;
    font-size: 16px;
    font-weight: 400;
    padding: 10px 16px;
    white-space: nowrap;
    width: 100%;
    text-align: left;
}

.export-btn-csv:hover {
    background-color: var(--blue-100);
}

#fs-expand-btn {
    display: flex;
    align-items: center;
    height: 32px;
    font-size: 14px;
    font-weight: 600;
    margin-right: 20px;
    padding: 6px 12px 6px 12px;
    white-space: nowrap;
    background-color: var(--blue-600);
    border-radius: 6px;
}

#fs-expand-btn:hover {
    display: flex;
    align-items: center;
    height: 32px;
    font-size: 14px;
    font-weight: 600;
    margin-right: 20px;
    padding: 6px 12px 6px 12px;
    white-space: nowrap;
    background-color: var(--blue-700);
    border-radius: 6px;
}

#oser-dash-select-menu-wrapper {
    display: flex;
    flex-direction: row;
    padding: 0 0 0 5px;
    z-index: 1001;
}

#dashboard-body {
    background-color: white;
    padding: 15px 5px 10px 15px;
    min-height: 84vh;
    height: fit-content;
    width: 100%;
    position: relative;
}

#fullscreen-wrapper {
    flex-grow: 1;
}

.dashboard-body-displaying {
    display: flex;
    flex-direction: column;
}

.carousel-wrapper {
    margin-top: 1%;
    height: auto;
}

.truncate-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(99%);
    padding: 5px;
    border-style: solid;
    border-radius: 5px;
    height: 100%;
    width: 100%;
    margin: 5px;
}

.ellipsis-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(99%);
}

.card-text {
    padding: 0;
    margin: 0;
}

.level-text {
    width: 22px;
}

#facility-select-box {
    width: max-content;
    min-width: 20%;
}

#search-select-box {
    min-width: 20%;
    margin: 0 0 0 2%;
}

.responder-card {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px;
    border: 1px solid lightgray;
    border-radius: 5px;
    height: 85px;
    width: 100%;
    margin: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.responder-card:hover {
    cursor: pointer;
}

.responder-card-img {
    margin: 0 10px 0 10px;
    padding: 0;
    width: auto;
    height: 82%;
    object-fit: cover;
    aspect-ratio: 1;
    border-radius: 4px;
}

.responder-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 1px;
    overflow: auto;
}

.responder-info-name {
    margin: 0;
    padding: 0;
    font-weight: 600;
    width: calc(99%);
}

.responder-info-title {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    font-weight: 400;
    width: calc(99%);
}

.responder-info-login-sites {
    display: flex;
    flex-direction: row;
    width: calc(99%);
}

.responder-info-pipe {
    background-color: var(--responder-grey-1);
    height: 15px;
    width: 1px;
    margin: 2px 5px 2px 3px;
    flex: none;
}

.responder-info-login-sites p:not(:last-child) {
    margin-right: 5px;
}

.responder-info-login-sites p:last-child {
    font-weight: 200;
}

.see-all-view-wrapper {
    display: flex;
    flex-direction: column-reverse;
    margin: 6px 26px 0 0;
}

.see-all-card-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1%;
    padding: 0;

}

.see-all-button-group {
    display: flex;
    justify-content: flex-end;
    margin: 0 -4px 2px 0;
}

.see-all-back-button {
    border: none;
    border-radius: 4px;
    background-color: var(--blue-100);
    height: 32px;
    color: var(--blue-700);
    font-size: 14px;
    font-weight: 500;
    padding: 0 12px;
}

.card-tooltip {
    position: absolute;
    top: 10px;
    background-color: white;
    border: solid 1px;
    border-radius: 2px;
    border-color: gray;
    filter: opacity(1);
    padding: 5px;
    z-index: 10000;
}

.carousel-custom-button-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 12px;
}

.carousel-custom-seeall {
    position: absolute;
    margin-right: 1px;
    outline: 0;
    transition: all .5s;
    z-index: 1000;
    border: 0;
    background: none;
    opacity: 1;
    cursor: pointer;
    font-weight: 600;
}

.carousel-custom-button {
    position: absolute;
    margin-right: 1px;
    outline: 0;
    transition: all .5s;
    border-radius: 35px;
    z-index: 1000;
    border: 0;
    background: rgba(238, 238, 238, 0.5);
    min-width: 30px;
    min-height: 30px;
    opacity: 1;
    cursor: pointer;
}

.carousel-custom-button-seeall {
    margin: 0 96px;
    border-radius: 4px;
    background-color: var(--blue-100);
    height: 32px;
    color: var(--blue-700);
    font-size: 14px;
    font-weight: 500;
    padding: 0 12px;
}

.carousel-custom-button-back {
    margin: 0 60px;
    color: var(--blue-700);
    background-color: var(--blue-100);
    font-weight: 500;
}

.carousel-custom-button-forward {
    margin: 0 15px;
    color: var(--blue-700);
    background-color: var(--blue-100);
    font-weight: 500;
}

.disable {
    opacity: .5;
    pointer-events: none;
    cursor: not-allowed;
}

.carousel-container {
    width: 100%;
    height: 50%;
}

.carouselBase {
    display: flex;
    flex-direction: column-reverse;
    margin-left: -6px;
}

.carousel-item {
    padding: 5px;
    border-style: 1px solid var(--responder-grey-1);
    border-radius: 4px;
    height: 100%;
    width: 100%;
    margin: 5px;
}

.react-multi-carousel-track {
    transition: none 0s ease 0s;
    overflow: unset;
}

.react-multi-carousel-list {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    padding: 5px 0;
}

.no-transform {
    transform: none !important;
}

.last-refresh-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.last-refresh {
    margin: 0 0 0 1%;
    padding: 0;
}

.refresh-dot {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    margin: 0 10px 0 20px;
    flex-shrink: 0;
}

.title-info-icon {
    cursor: pointer;
    margin: 0 0 0 8px;
    color: var(--gray-500);
    position: relative;
}

.title-info-text {
    position: absolute;
    top: 36px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    background-color: var(--gray-700);
    border-radius: 4px;
    width: 235px;
    padding: 8px 12px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.info-text-content {
    text-wrap: balance;
    font-size: 13px;
}

.info-text-arrow {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid var(--gray-700);
    position: absolute;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
}

@-webkit-keyframes pulse {
    0% {
        box-shadow: 0 0 0 1px rgba(101, 255, 120, 0.3),
            0 0 0 2px rgba(101, 255, 120, 0.3), 0 0 0 4px rgba(101, 255, 120, 0.3),
            0 0 0 6px rgba(101, 255, 120, 0.3);
    }

    100% {
        box-shadow: 0 0 0 2px rgba(101, 255, 120, 0.3),
            0 0 0 3px rgba(101, 255, 120, 0.3), 0 0 0 5px rgba(101, 255, 120, 0.3),
            0 0 0 7px rgba(101, 255, 120, 0);
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 1px rgba(101, 255, 120, 0.3),
            0 0 0 2px rgba(101, 255, 120, 0.3), 0 0 0 4px rgba(101, 255, 120, 0.3),
            0 0 0 6px rgba(101, 255, 120, 0.3);
    }

    100% {
        box-shadow: 0 0 0 2px rgba(101, 255, 120, 0.3),
            0 0 0 3px rgba(101, 255, 120, 0.3), 0 0 0 5px rgba(101, 255, 120, 0.3),
            0 0 0 7px rgba(101, 255, 120, 0);
    }
}

.green-dot {
    background-color: var(--green-300);
    box-shadow: 0 0 4px 3px var(--green-300);
    animation: pulse 1.0s linear infinite;
}

.red-dot {
    background-color: var(--red-500);
    box-shadow: 0 0 4px 3px var(--red-500);
}

.profile-view {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    right: 0;
    width: 40%;
    height: 100%;
    background-color: white;
    padding: 10px 20px;
    box-sizing: border-box;
    transition: transform 0.3s ease-in-out;
    border-left: 2px solid lightgray;
    z-index: 1000;
}

.profile-view-close-wrapper {
    display: flex;
    flex-direction: column;
    align-items: right;
    margin-bottom: 10px;
}

.profile-view-responder-box {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 16px 14px;
    border-style: solid;
    border-width: 1px;
    border-color: lightgray;
    border-radius: 5px;
    margin: 0 0 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.profile-view-info-box {
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 14px 16px;
    margin: 0 0 10px 0;
}

.profile-view-emp-img {
    margin: 0 10px 0 0;
    padding: 0;
    width: 97px;
    height: 97px;
    object-fit: cover;
    aspect-ratio: 1;
    border-radius: 4px;
}

.profile-view-responder-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 1px;
}

.profile-view-emp-info-font {
    font-size: 14px;
}

.profile-view-name-text {
    margin: 0;
    padding: 0;
    font-size: 22px;
    font-weight: 600;
}

.profile-view-title-text {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 400;
}

.profile-view-login-sites {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    font-weight: 400;
}

.profile-view-info-pipe {
    background-color: var(--responder-grey-1);
    height: 20px;
    width: 1px;
    margin: 2px 7px 0 0;
    flex: none;
}

.profile-view-login-sites p:not(:last-child) {
    margin-right: 7px;
}

.profile-view-login-sites p:last-child {
    font-weight: 200;
}

.profile-view-section-title {
    font-size: 16px;
    font-weight: 700;
}

.capitalize {
    text-transform: capitalize;
}

.profile-view-info-row {
    display: flex;
    flex-direction: row;
}

.profile-view-info-row-col {
    display: flex;
    flex-direction: column;
    width: 50%;
    font-size: 14px;
    font-weight: 400;
}

.profile-view-info-key {
    color: gray;
}

.responder-multi-carousel-track {
    height: 30%;
}

#no-responders-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 100px 0 0 0;
}

#no-responders-message-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--gray-900);
}

.no-responders-message-text {
    font-size: 14px;
    font-weight: 400;
    color: var(--gray-700);
}

#responder-loading-overlay {
    z-index: 1;
    position: fixed;
    height: 35%;
    width: 30%;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    opacity: 0.95;
    border-radius: 4px;
    transform: translate(-50%, -50%);
    text-align: center;
}

#responder-loading-spinner {
    margin: 0 0 16px 0;
    color: blue;
}

#responder-loading-text {
    font-size: 16px;
}

#site-popover {
    background-color: var(--popover-background);
    color: white;
}

#bottom-page-info-message {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-left: 1%;
}

#bottom-page-info-message-text {
    color: black;
    font-weight: 400;
    font-size: 14px;
}

.position-fixed {
    position: fixed;
}

@media (max-width: 700px) {
    #oser-dash-main-title-fullscreen-btn-wrapper {
        flex-direction: row;
    }

    #oser-dash-main-title-count-separator {
        display: flex;
    }

    #oser-dash-main-title-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        white-space: nowrap;
        align-items: center;
    }

    .refresh-dot {
        height: 6px;
        width: 6px;
        border-radius: 50%;
        margin: 0 10px 0 6px;
        flex-shrink: 0;
    }

    .see-all-view-wrapper {
        display: flex;
        flex-direction: column-reverse;
        margin: 6px 26px 0 0;
    }

    .see-all-card-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 1%;
        margin: 3% 3% 0 0;
        padding: 0;
    
    }

    .see-all-button-group {
        display: flex;
        justify-content: flex-end;
        margin: 0 -4px 2px 0;
    }

    .see-all-back-button {
        border: none;
        border-radius: 4px;
        background-color: var(--blue-100);
        height: 32px;
        color: var(--blue-700);
        font-size: 14px;
        font-weight: 500;
        padding: 0 12px;
    }

    #oser-dash-select-menu-wrapper {
        display: flex;
        flex-direction: column;
        padding: 5px 0 0 0;
        z-index: 1001;
    }

    #search-select-box {
        padding: 5px 0 0 0;
        width: 95%;
        margin: 0;
    }

    #responder-options-menu {
        display: flex;
        align-items: baseline;
    }

    #facility-select-box {
        width: 95%;
    }

    #fs-expand-btn {
        visibility: hidden;
        height: 0;
    }

    #export-btn {
        padding: 4px 12px 2px 12px;
    }

    .responder-type-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 0 12px 0;
    }

    .carousel-custom-button-group {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        margin: 12px 0;
    }

    .carousel-custom-button {
        position: absolute;
        margin-right: 1px;
        outline: 0;
        transition: all .5s;
        border-radius: 35px;
        z-index: 1000;
        border: 0;
        background: rgba(238, 238, 238, 0.5);
        min-width: 38px;
        min-height: 38px;
        opacity: 1;
        cursor: pointer;
    }
    
    .carousel-custom-button-seeall {
        margin: 0 105px;
    }
    
    .carousel-custom-button-back {
        margin: 0 60px;
        color: var(--blue-700);
        background-color: var(--blue-100);
        font-weight: 500;
    }
    
    .carousel-custom-button-forward {
        margin: 0 15px;
        color: var(--blue-700);
        background-color: var(--blue-100);
        font-weight: 500;
    }

    #responder-loading-overlay {
        z-index: 1001;
        position: fixed;
        height: 35%;
        width: 50%;
        bottom: 0;
        left: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background-color: white;
        opacity: 0.95;
        border-radius: 4px;
        transform: translate(-50%, -50%);
        text-align: center;
    }

    #no-responders-message {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 40px 0 0 0;
    }

    #no-responders-message-title {
        font-size: 20px;
        text-align: center;
        font-weight: 600;
        color: var(--gray-900);
    }

    .no-responders-message-text {
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        color: var(--gray-700);
    }

    .profile-view {
        position: fixed;
        display: flex;
        flex-direction: column;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: white;
        padding: 10px 20px;
        box-sizing: border-box;
        transition: transform 0.3s ease-in-out;
        border-left: 2px solid lightgray;
        z-index: 1000;
    }
}
