.baseDashContainer {
    margin-left: 0;
    margin-bottom: 5%;
}

.dashView {
    display: flex;
    width: 80%;
}

.dashPartition {
    
    width: 40%;
}

.dashLabel {
    text-transform: capitalize;
    font-size: 1.5em;
    font-weight: 600;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

li {
    display: flex;
    align-items: center;
}

li span {
    flex: 1;
    margin: 2px 10px;
}

li div {
    width: max-content;
}
