#dot {
    height: 0.9em;
    width: 0.9em;
    border-radius: 50%;
    margin: 0 10px;
    display: inline-block;
}

.green {
    background-color: #24B264;
}

.grey {
    background-color: #B5B4B4;
}
