.fullscreen-enabled .p .h2 .div {
    /* style components in FullScreen view */
    background-color: #ffffff;
    color: red;
}

.fullscreen-enabled {
    /* style FullScreen view */
    background-color: #fff;
    color: #000;
    padding: 1.5% .5% 1% 1.5%;
}

.fullscreen-view-close-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 25px;
    background-color: transparent;
}

.fullscreen-view-title-wrapper {
    display: flex;
    gap: 15px;
    align-items: center;
}

.fullscreen-enabled .responder-multi-carousel-track {
    height: 30%;
}

.fullscreen-enabled .carousel-wrapper {
    margin-top: 1%;
    height: 24%;
}

.fullscreen-enabled #dashboard-body-div {
    height: 100%;
}

.fullscreen-enabled .responder-card {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 2%;
    border: 2px solid lightgray;
    border-radius: 5px;
    height: 125px;
    width: 100%;
    margin: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.fullscreen-enabled .responder-card-img {
    margin: 0 10px 0 5px;
    padding: 0;
    width: auto;
    height: 6rem;
    border-radius: 0.2rem;
}

.fullscreen-enabled .responder-info-name {
    margin: 0;
    padding: 0;
    font-size: 1.3em;
    font-weight: 600;
}

.fullscreen-enabled .responder-info-title {
    margin: 0;
    padding: 0;
    font-size: 1.3em;
    font-weight: 400;
}

.fullscreen-enabled .responder-info-login-sites {
    display: flex;
    flex-direction: row;
    font-size: 1.3em;
}

.fullscreen-enabled .responder-info-login-sites p:not(:last-child) {
    margin-right: 0.5rem;
}

.fullscreen-enabled .responder-info-login-sites p:last-child {
    font-weight: 200;
}

#fs-expand-btn {
    float: right;
    color: white;
    background-color: var(--blue-600);
    border-width: 0;
    border-radius: 5px;
    padding: 5px;
}

#fs-expand-btn:hover {
    float: right;
    color: white;
    background-color: var(--blue-700);
    border-width: 0;
    border-radius: 5px;
    padding: 5px;
}

.rp-banner {
    font-size: 1.1rem;
}
