.incidentHistoryMessage {
    display: flex;
    font-size: 0.85rem;
    margin-left: 1rem;
    margin-top: 0.4rem;
    margin-bottom: 0;
}

.incidentHistoryMessageLink {
    z-index: 10;
    margin-top: -0.5rem;
    margin-left: -0.75rem;
    margin-right: -0.75rem;
}
