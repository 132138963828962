:root {
    --squid-ink: #161d26;
    
    --blue-100: #ebecff;
    --blue-200: #d7dcff;
    --blue-300: #b6c1ff;
    --blue-400: #8297ff;
    --blue-500: #5070ff;
    --blue-600: #0d4aff;
    --blue-700: #0036c8;
    --blue-800: #00289c;
    --blue-900: #001b74;

    --gray-100: #f9fafb;
    --gray-200: #eaecf0;
    --gray-300: #d0d5dd;
    --gray-400: #98a2b3;
    --gray-500: #667085;
    --gray-600: #475467;
    --gray-700: #344054;
    --gray-800: #1d2939;
    --gray-900: #101828;

    --green-100: #e5feef;
    --green-200: #c1f9d7;
    --green-300: #97efbe;
    --green-400: #65e79d;
    --green-500: #37d17c;
    --green-600: #24b264;
    --green-700: #13954e;
    --green-800: #187d46;
    --green-900: #18633b;

    --yellow-100: #fdf3e1;
    --yellow-200: #fbdf84;
    --yellow-300: #f8cb83;
    --yellow-400: #f5b652;
    --yellow-500: #f4a630;
    --yellow-600: #f29718;
    --yellow-700: #ee8c15;
    --yellow-800: #f5b652;
    --yellow-900: #e16e10;

    --red-100: #ffe3e0;
    --red-200: #ffccc7;
    --red-300: #ffa79f;
    --red-400: #ff7468;
    --red-500: #fa4939;
    --red-600: #e52817;
    --red-700: #c32112;
    --red-800: #a11f13;
    --red-900: #852017;
}
