.baseTableContainer {
    display: block;
}

.dashLabel {
    text-transform: capitalize;
    font-size: 1.5em;
    font-weight: 600;
}

#permissionsTeams {
    width: 80%;
    margin-bottom: 1%;
}