.modalTitle {
    font-size: 16px !important;
}

.modalHeader {
    padding-left: 8% !important;
    padding-right: 8% !important;
}

.formLabel {
    font-size: 14px !important;
}

.formControl {
    border-color: #667085 !important;
    height: 40px !important;
}

.saveButton {
    background-color: #0D4AFF !important;
    color: white !important;
    border: none !important;
    border-radius: 4px !important;
    width: 50% !important;
    height: 40px !important;
    align-self: center !important;
    font-size: 14px !important;
}

.trashButton {
    background-color: transparent !important;
    color: grey !important;
    border: none !important;
    box-shadow: none !important;
}

.deleteCancelButton {
    background-color: transparent !important;
    border: none !important;
    width: 30% !important;
    height: 30px !important;
    font-size: 14px !important;
    align-self: center !important;
}

.formBody {
    font-size: 1.1rem;
    margin: 10px 30px 10px 80px;
    display: flex;
    flex-direction: column;
    width: 300px;
}

.footer {
    flex: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.footer button {
    width: 310px;
    height: 40px;
    border: none;
    color: white;
    border-radius: 10px;
    font-size: 20px;
    margin: 10px 0px 10px 0px;

}

.swal-custom-title {
    font-size: 20px;
    color: black;
    font-weight: 500;
    text-align: center;
    padding: 30px 20px 20px 20px;
}

.swal-confirm-button {
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    width: 120px;
    height: 40px;
}

.swal-cancel-button {
    color: #0036C8;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    width: 120px;
    height: 40px;
}

.swal-buttons-vertical {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.react-tel-input .flag-dropdown {
    display: none;
}

.react-tel-input input[type='tel'] {
    padding-left: 10px;
}

.customPhoneInput {
    border-color: #667085 !important;
    border-width: 1px !important;
    box-shadow: none !important;
    font-family: 'Inter', Helvetica, Arial, sans-serif !important;
    font-size: 14px !important;
}

.customPhoneInput:focus {
    box-shadow: 0 0 0 1px #0D4AFF !important;
}
