body {
    background-color: #FFFFFF;
    width: 100vw;
    height: 100vh;
    margin: auto;
    padding: 0;
    font-size: 0.8rem;
    font-family: 'Inter', Helvetica, Arial, sans-serif;
    font-weight: 500;
}

.form-control {
    font-size: 0.8rem;
}

.Toastify__toast-container--top-right {
    width: 500px !important;
}

.react-bootstrap-table table {
  table-layout: auto;
}

.custom-control-input:checked ~ .custom-control-label::before {
    background-color: #0D4AFF;
}

#changeRole.dropdown-toggle {
    background-color: #EAECF0;
    color: #000000;
    border-color: #EAECF0;
    font-weight: 500;
    border-radius: 5px;
}

#pageDropDown.dropdown-toggle {
    background-color: #EAECF0;
    color: #000000;
    border-color: #EAECF0;
    font-weight: 400;
    border-radius: 5px;
    margin-top: 10px;
}

.nav-pills .nav-link {
    color: #000000;
    font-weight: 350
}

.nav-pills .nav-link.active {
    color: #0D4AFF;
    background-color: #EBECFF;
    border-radius: 3px;
}

.custom-tabs {
    position: relative;
}
.custom-tabs:after {
    content: '';
    position: absolute;
    left: -15px;
    bottom: 0;
    width: calc(100% + 10px);
    border-bottom: 1px solid #EAECF0;
}

.table-responsive {
    border: 1px solid #E7E7ED;
    border-radius: 5px;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
}

.table-responsive table {
    width: 100%;
    table-layout: auto;
    border-collapse: collapse;
}

/* Remove internal borders for table cells */
.table-responsive td {
    border: none !important;
    padding-left: 50px;
    padding-top: 30px;
    white-space: nowrap;
    align-content: center;
}

/* Add underline for column headers */
.table-responsive th {
    padding-left: 50px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #E7E7ED !important;
    border-top: none !important;
}

.hide-table-headers thead {
    display: none;
}

.page-item.active .page-link {
    background-color: #0D4AFF;
    color: white;
    border: 1px solid #0D4AFF;
    border-radius: 5px;
}

.page-item .page-link {
    margin-top: 10px;
    background-color: #EAECF0;
    color: black;
    border: 1px solid #EAECF0;
    border-radius: 5px;
}

.custom-checkbox-container {
    display: flex;
    align-items: center;
}

.custom-checkbox-container label {
    margin-top: 7px;
}

.custom-checkbox {
    width: 20px;
    height: 20px;
}

.custom-checkbox:checked {
    accent-color: #0D4AFF;
}

.custom-checkbox-container {
    display: flex;
    align-items: center;
}

.custom-checkbox-container label {
    margin-top: 7px;
}

.custom-checkbox {
    width: 20px;
    height: 20px;
}

.custom-checkbox:checked {
    accent-color: #0D4AFF;
}

.custom-checkbox-container {
    display: flex;
    align-items: center;
}

.custom-checkbox-container label {
    margin-top: 7px;
}

.custom-checkbox {
    width: 20px;
    height: 20px;
}

.custom-checkbox:checked {
    accent-color: #0D4AFF;
}

.react-bootstrap-table .non-filterable-column {
    padding-bottom: 30px;
}
